@import "~react-image-gallery/styles/css/image-gallery.css";
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.root {
  --swiper-navigation-size: 14px;

  --swiper-theme-color: #fff !important;
  size: 24px;
}
.swiper-button-custom {
  color: white !important;
  top: 40% !important;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: "Courier New", Courier, monospace;
  font-size: 22px !important;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  -moz-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-variant: initial;
  line-height: 1;
}
.swiper-button-prev,
.swiper-button-next {
  color: #8f1838 !important;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 14;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px !important;
}
.swiper-button-disabled{
  display: none !important;
}
.MuiPaginationItem-outlined {
  border: 0px solid rgba(0, 0, 0, 0.23);
}
.MuiPaginationItem-sizeLarge {
  height: 40px;
  padding: 0 10px;
  font-size: 0.9375rem;
  min-width: 40px;
  border-radius: 20px;
  border-width: 0 !important;
}
.MuiPaginationItem-page:hover {
  background-color: #8f1838 !important;
  color: #fff !important;
}
.MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #fff !important;
  border: 0px solid #fff;
  background-color: #8f1838 !important;
}
.css-1df1ja3,
.css-1df1ja3 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  top: -0;
  -webkit-transform: translate(25%, 25%);
  -moz-transform: translate(25%, 25%);
  -ms-transform: translate(25%, 25%);
  transform: translate(25%, 25%);
  border-width: 0em !important;
  background: #8f1838 !important;
  width: 1em !important;
  height: 1em !important;
}
.MuiListItemText-root {
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 0 !important;
  margin-bottom: 0.5 !important;
}
.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0px !important;
}
.MuiListItem-root {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiFab-root {
  color: #fff !important;
  background-color: #8f1838 !important ;
  align-self: self-end !important;
  margin-right: 25px !important;
  margin-bottom: 25px !important;
}
.MuiFab-root:hover {
  color: #fff !important;
  background-color: #d0a152 !important ;
  align-self: self-end !important;
  margin-right: 25px !important;
  margin-bottom: 25px !important;
}
.Toaster__alert_close {
  display: none;
}

.ribbon-wrapper {
  z-index: 3;
  width: 106px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  right: -6px;
}

.ribbon {
  z-index: 3;
  font-size: 15px;
  line-height: 18px;
  color: #333;
  font-weight: 700;
  text-align: center;
  font-family: Lato;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -11px;
  top: 26px;
  width: 150px;
  background-color: #8f1838;
  color: #fff;
  box-shadow: 0 4px 6px #8f1838;
}
.modal-video-close-btn {
  position: absolute;
  z-index: 3;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 18px !important;
  height: 25px !important;
  overflow: hidden;
  border: none;
  background: transparent;
}


.blink-me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}